const imagesAreaLazer = [];
const imagesEJ = [];
const imagesFD = [];
const imagesJK = [];
const imagesJW = [];
const imagesMT = [];
const imagesPC = [];
const baseURL = `https://res.cloudinary.com/dgoa4lptq/image/upload`;

for (var i = 1; i < 15; i++) {
  imagesAreaLazer.push({
    original: `${baseURL}/area_de_lazer/${i}.jpg`,
    thumbnail: `${baseURL}/area_de_lazer/thumbnail/${i}.jpg`,
  });
}

for (var i = 1; i < 5; i++) {
  imagesEJ.push({
    original: `${baseURL}/EJ/${i}.jpg`,
    thumbnail: `${baseURL}/EJ/thumbnail/${i}.jpg`,
  });
}

for (var i = 1; i < 13; i++) {
  imagesFD.push({
    original: `${baseURL}/FD/${i}.jpg`,
    thumbnail: `${baseURL}/FD/thumbnail/${i}.jpg`,
  });
}

for (var i = 1; i < 7; i++) {
  imagesJK.push({
    original: `${baseURL}/JK/${i}.jpg`,
    thumbnail: `${baseURL}/JK/thumbnail/${i}.jpg`,
  });
}

for (var i = 1; i < 13; i++) {
  imagesJW.push({
    original: `${baseURL}/JW/${i}.jpg`,
    thumbnail: `${baseURL}/JW/thumbnail/${i}.jpg`,
  });
}

for (var i = 1; i < 13; i++) {
  imagesMT.push({
    original: `${baseURL}/MT/${i}.jpg`,
    thumbnail: `${baseURL}/MT/thumbnail/${i}.jpg`,
  });
}

for (var i = 1; i < 6; i++) {
  imagesPC.push({
    original: `${baseURL}/PC/${i}.jpg`,
    thumbnail: `${baseURL}/PC/thumbnail/${i}.jpg`,
  });
}

export const availableProjects = [
  {
    projectName: "Projeto F | D",
    show: false,
    images: imagesFD,
  },
  {
    projectName: "Projeto J | K",
    show: false,
    images: imagesJK,
  },
  {
    projectName: "Projeto J | W",
    show: false,
    images: imagesJW,
  },
  {
    projectName: "Projeto M | T",
    show: false,
    images: imagesMT,
  },
  {
    projectName: "Projeto P | C",
    show: false,
    images: imagesPC,
  },
  {
    projectName: "Área de Lazer",
    show: false,
    images: imagesAreaLazer,
  },
  {
    projectName: "Projeto E | J",
    show: false,
    images: imagesEJ,
  },
];

import "../assets/styles/Sobre.css";

function Sobre() {
  return (
    <div className='sobre-container'>
      <div className='texto-e-img'>
        <div className='txt'>
          <h1>Minha trajetória</h1>
          <p>
            Há mais de 8 anos atuando como profissional em arquitetura e design
            de interiores, Thaís Celina Klabunde é formada na FURB -
            Universidade Regional de Blumenau, desde 2012. É, também, pós
            graduada em Gestão de Projetos, no IPOG Florianópolis-SC (2017).
          </p>
          <p>
            Iniciou sua trajetória profissional na época da faculdade,
            estagiando em um escritório de Engenharia. Formada, passou a atuar
            na Prefeitura de Blumenau-SC como gerente de projetos. Desenvolveu
            projetos urbanísticos de praças e parques na cidade.
          </p>
          <p>
            Em 2014, inaugurou o escritório Messen Arquitetura com três sócias,
            onde entregou mais de 150 projetos residenciais e comerciais. Foram
            7 anos dedicados a sociedade até que, em 2021, fundou a TK
            Arquitetura.
          </p>
          <p>
            A TK Arquitetura nasce para realizar o sonho de cada um dos seus
            clientes. Através de projetos exclusivos e personalizados, o
            conceito chave é tornar realidade os desejos e vontades dos seus
            clientes com espaços únicos e criativos, que juntam beleza, conforto
            e funcionalidade.
          </p>
          <p>
            A proposta oferece o que há de mais atual no mundo da arquitetura e
            design de interiores. Com atendimentos presenciais e virtuais, a TK
            Arquitetura conta com uma estrutura que objetiva cumprir cronogramas
            acordados, assim como proporcionar o melhor custo-benefício aos seus
            clientes.
          </p>
          <p>
            Para isso, acredita plenamente, que manter próximos arquiteto e
            cliente no dia a dia, é a segurança necessária para que, ao final da
            obra, todos estalejam orgulhosos e satisfeitos com o trabalho
            realizado.
          </p>
        </div>
        <div className='perfil-img'>
          <img src='https://res.cloudinary.com/dgoa4lptq/image/upload/v1691417416/Sobre/sobre_img.jpg' />
        </div>
      </div>
    </div>
  );
}

export default Sobre;

import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../assets/styles/ImageGallery.css";

function Gallery(props) {
  useEffect(() => {
    console.log("App Component did mount");
  });

  return (
    <div className='gallery'>
      <div className='gallery-close-button'>
        <IoClose size='50px' onClick={props.onClick} />
      </div>
      <ImageGallery
        items={props.images}
        showIndex={true}
        showFullscreenButton={false}
        showPlayButton={false}
        lazyLoad={true}
      />
    </div>
  );
}

export default Gallery;

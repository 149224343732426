function Logo() {
  return <img src='logo.png' alt='logo' className='logo img' />;
}

function TabaMagazine() {
  return <img src='tabaMagazine.jpg' />;
}

const baseURL = `https://res.cloudinary.com/dgoa4lptq/image/upload`;

const sliderData = [
  {
    image: `${baseURL}/v1691416416/Slider/slide_01.jpg`,
  },
  {
    image: `${baseURL}/v1691416560/Slider/slide_02.jpg`,
  },
  {
    image: `${baseURL}/v1691416425/Slider/slide_03.jpg`,
  },
  {
    image: `${baseURL}/v1691416424/Slider/slide_04.jpg`,
  },
];

export default {
  Logo,
  TabaMagazine,
  sliderData,
};

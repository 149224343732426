import React, { useEffect, useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import "../assets/styles/Inicio.css";
import images from "../assets/images/Images";

const slides = images.sliderData;

function Inicio() {
  const [current, setCurrent] = useState(0);
  const MINUTE_MS = 3000;
  const LENGTH = slides.length;

  const nextSlide = () => {
    setCurrent(current === LENGTH - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? LENGTH - 1 : current - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, [current]);

  return (
    <>
      <div className="inicio-container">
        {/* <div className="div-left">
                    <FaChevronLeft
                        className="left-arrow-lg"
                        onClick={prevSlide}
                    />
                </div> */}
        <div className="div-image">
          {slides.map((slide, index) => {
            return (
              <div key={index}>
                {index === current && <img src={slide.image} alt="" />}
              </div>
            );
          })}
        </div>
        {/* <div className="div-right">
                    <FaChevronRight
                        className="right-arrow-lg"
                        onClick={nextSlide}
                    />
                </div> */}
      </div>
    </>
  );
}

export default Inicio;

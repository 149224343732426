import React, { useState } from "react";
import { jumpToElementByClass } from "../utils/Util";
import images from "../assets/images/Images";
import "../assets/styles/Menu.css";
import { IoMenuOutline, IoClose } from "react-icons/io5";

function Menu() {
  const [open, setOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const closeMobileMenu = () => setOpen(false);

  const handleShowNavbar = (elementClass) => {
    const element = document.getElementsByClassName(elementClass)[0];
    if (element) {
      element.scrollIntoView();
      element.focus();
    }
    setOpen(!open);
  };

  const menuHamburguer = (
    <>
      <IoMenuOutline size="60px" onClick={() => setOpen(!open)} />
    </>
  );
  const closeIcon = (
    <>
      <IoClose size="50px" onClick={() => setOpen(!open)} />
      <ul className="hamburguer">
        <li onClick={() => handleShowNavbar("inicio-container")}>Início</li>
        <li onClick={() => handleShowNavbar("sobre-container")}>Sobre</li>
        <li onClick={() => handleShowNavbar("projetos-container")}>Projetos</li>
        <li onClick={() => handleShowNavbar("media-container")}>Mídia</li>
        <li onClick={() => handleShowNavbar("contato-container")}>Contato</li>
      </ul>
    </>
  );

  return (
    <div className="navi-bar">
      <div className="menu-container">
        <div className="logo">
          <images.Logo />
        </div>
        <div className="navigation">
          <h3 onClick={jumpToElementByClass("inicio-container")}>Início</h3>
          <h3 onClick={jumpToElementByClass("sobre-container")}>Sobre</h3>
          <h3 onClick={jumpToElementByClass("projetos-container")}>Projetos</h3>
          <h3 onClick={jumpToElementByClass("media-container")}>Mídia</h3>
          <h3 onClick={jumpToElementByClass("contato-container")}>Contato</h3>
        </div>
        <div className="menu-hamburguer">
          {open ? closeIcon : menuHamburguer}
        </div>
      </div>
    </div>
  );
}

export default Menu;

import { useState } from "react";
import { availableProjects } from "../utils/availableProjects";
import Gallery from "./Gallery";
import "../assets/styles/Projetos.css";

function Projetos() {
  const [projects] = useState(availableProjects);
  const [gallery, setGallery] = useState([]);
  const [modal, setModal] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  const handleOnClick = (i) => {
    setGallery(
      <div className='modal-backdrop'>
        <Gallery images={projects[i].images} onClick={handleClose} />
      </div>
    );
    setModal(true);
  };

  const handleOnMouseEnter = (i) => {
    console.log("mouse enter");
  };

  return (
    <>
      <div className='projetos-container'>
        <h1>Projetos</h1>
        <div className='projetos-todos'>
          {projects.map((project, index) => {
            return (
              <figure key={index} onMouseEnter={(i) => handleOnMouseEnter(0)}>
                <img
                  className='projeto-img'
                  src={project.images[0].original}
                  alt={project.projectName}
                  onClick={() => handleOnClick(index)}
                />
                <figcaption className='projeto-nome'>
                  {project.show ? <h1>{project.projectName}</h1> : null}
                </figcaption>
              </figure>
            );
          })}
          {modal ? gallery : false}
        </div>
      </div>
    </>
  );
}

export default Projetos;

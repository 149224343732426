import Menu from "../components/Menu";
import Inicio from "../components/Inicio";
import Sobre from "../components/Sobre";
import Projetos from "../components/Projetos";
import Media from "../components/Media";
import Contato from "../components/Contato";

function Home() {
  return (
    <>
      <Menu />
      <Inicio />
      <Sobre />
      <Projetos />
      <Media />
      <Contato />
    </>
  );
}

export default Home;

import React, { useRef, useState } from "react";
import { BsInstagram } from "react-icons/bs";
import InstaFeed from "./InstaFeed";
// import secrets from "../secrets.json";
import "../assets/styles/Contato.css";

function Contato() {
  return (
    <div className='contato-container'>
      <div className='contato-info'>
        <h3>Nos acompanhe no Instagram:</h3>
        <h3>
          <a href='https://www.instagram.com/tk.arq/' target='_blank'>
            <BsInstagram size={"30px"} />
            <span>@tk.arq</span>
          </a>
        </h3>
        {/* <InstaFeed token={secrets.access_token} limit={12} /> */}
        <div className='contato'>
          <p>
            Telefone/Whatsapp:
            <a
              href='https://chatwith.io/s/tk-arquitetura-thais-klabunde'
              target='_blank'
            >
              (47) 99176-5874
            </a>
          </p>
          <p>E-mail: contato@arquiteturatk.com.br</p>
        </div>
      </div>
      <footer>
        <h5>Thaís Celina Klabunde | CAU A86893-0 | (47) 99176-5874</h5>
      </footer>
    </div>
  );
}

export default Contato;

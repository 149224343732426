import React, { useCallback, useState } from "react";
import images from "../assets/images/Images";
import "../assets/styles/Media.css";

function Media() {
  const [fullScreen, setFullScreen] = useState(false);

  const handleClick = (event) => {
    setFullScreen((current) => !current);
  };

  return (
    <div className="media-container">
      <div className="info-e-midia">
        <div className="info">
          <h1>Publicações:</h1>
          <p>
            Veículo de publicação: Revista Taba Magazine | Tabajara Tênis Clube
          </p>
          <br />
          <p>Data de publicação: 01/10/2021</p>
        </div>
        <div
          className={fullScreen ? "img-fullscren" : ""}
          onClick={handleClick}
        >
          <images.TabaMagazine />
        </div>
      </div>
    </div>
  );
}

export default Media;
